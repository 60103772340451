import { Box, Chip } from "@mui/material";
import { Event } from "@root/utils/entities";
import { observer } from "mobx-react-lite";

export const EventCategories = observer(({ event }: { event: Event }) => (
  <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "0.5rem", flex: 1 }}>
    {event.categories?.map((category) => (
      <Chip
        key={`eventCategory${event.id}_${category.id}}`}
        label={category.name}
        sx={{
          border: "2px solid #1C1B1F",
          color: "#1C1B1F",
          fontWeight: 500,
          fontSize: "0.7rem",
          padding: 0
        }}
      />
    ))}
  </Box>
));